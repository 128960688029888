<template>
	<div :style="bg" >
		<Header :title="$t('setting.myFundList')"></Header>
		<div class="column-center-content main-content">
			<div v-for="item in fundList" :key="item.id" @click="goDetail(item)" style="margin: 5px 0px; padding: 10px; background-color: #FFFFFF; width: 95%; min-height: 80px; border-radius: 5px; display: flex; flex-direction: row;">
				<img style="margin-right: 10px;" src="../../assets/ammf.jpg" width="80px"/>
				<div style="width: 100%; display: flex; flex-direction: column; justify-content: space-between;">
					<label style="font-size: 18px; font-weight: bold;">非洲矿业基金</label>
					<div class="row-content" style="width: 100%; justify-content: space-between;">
						<label style="font-size: 14px; color: #999999;">{{item.createTime}}</label>
						<label style="font-size: 14px; color: #DC3545;">{{item.coastUsdt}} 
							<span>USDT</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	import { Indicator } from 'mint-ui';
	import moment from "moment";
	
	export default {
		name: 'AddressAdd',
		components: {
			Header
		},
		mounted() {
			Indicator.open({
				text: '',
				spinnerType: 'fading-circle'
			});
			
			this.$request.myFundList({}).then((data)=>{
				Indicator.close();
				data.forEach(temp => {
					temp.createTime = moment(temp.gmtCreate).format('YYYY-MM-DD')
				})
				this.fundList = data;						
			});
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				fundList: []
			}
		},
		methods: {
			goDetail(item) {
				this.$router.push({
					name: 'FundOrderDetail',
					params: item
				})
				localStorage.setItem('cur_fund', JSON.stringify(item));
			}
		}
	}
</script>

<style>
</style>
